<template>
  <b-row>
    <b-col lg="12" sm="12">
      <template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Year" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      id="fiscal_year_id"
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-button type="submit" size="sm" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4" class="text-right" v-if="showData">
                <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                  <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                </b-button>
            </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <body-card>
        <template v-slot:body>
          <div v-if="showData">
            <b-overlay :show="loading">
              <b-row>
                <b-col>
                  <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    {{ $t('teaGardenService.ex_garden_sale') }}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row class="justify-content-center mb-4">
                <b-col md="6" class="text-center">
                  (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                  <br>
                  (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                  <br>
                  (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
                </b-col>
              </b-row>
              <b-table-simple bordered small responsive>
                <b-thead>
                  <b-tr class="text-center">
                    <b-th rowspan="3">{{ $t('globalTrans.month') }}</b-th>
                    <b-th colspan="6">{{ $t('teaGardenService.loose_tea') }}</b-th>
                  </b-tr>
                  <b-tr class="text-center">
                    <b-th colspan="2">{{ getFiscalYearName(search.fiscal_year_ids[0]) }}</b-th>
                    <b-th colspan="2">{{ getFiscalYearName(search.fiscal_year_ids[1]) }}</b-th>
                    <b-th colspan="2">{{ getFiscalYearName(search.fiscal_year_ids[2]) }}</b-th>
                  </b-tr>
                  <b-tr class="text-center">
                    <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                    <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                    <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                    <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                    <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                    <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                <template v-if="resultData.length">
                  <tr v-for="(item, index) in resultData" :key="index" class="text-center">
                    <td>{{ getMonthName(item.month) }}</td>
                    <template v-for="(fiscalItem, fiscalIndex) in item.fiscal_year_info">
                      <b-td :key="'dm-' + fiscalIndex">
                        {{ fiscalItem.total_quantity > 0 ? $n(fiscalItem.total_quantity, { useGrouping: false }) : '' }}
                      </b-td>
                      <b-td :key="'dm-' + fiscalIndex">
                        {{ fiscalItem.total_avg_price > 0 ? $n(fiscalItem.total_avg_price, { useGrouping: false }) : '' }}
                      </b-td>
                    </template>
                  </tr>
                  <b-tr class="text-center" style="font-weight: bold;">
                    <b-td>{{ $t('globalTrans.total') }}</b-td>
                    <b-td>{{ firstYearQty > 0 ? $n(firstYearQty, { useGrouping: false }) : '' }}</b-td>
                    <b-td>{{ firstYearAvgPrice > 0 ? $n(firstYearAvgPrice, { useGrouping: false }) : '' }}</b-td>
                    <b-td>{{ secondYearQty > 0 ? $n(secondYearQty, { useGrouping: false }) : '' }}</b-td>
                    <b-td>{{ secondYearAvgPrice > 0 ? $n(secondYearAvgPrice, { useGrouping: false }) : '' }}</b-td>
                    <b-td>{{ thirdYearQty > 0 ? $n(thirdYearQty, { useGrouping: false }) : '' }}</b-td>
                    <b-td>{{ thirdYearAvgPrice > 0 ? $n(thirdYearAvgPrice, { useGrouping: false }) : '' }}</b-td>
                  </b-tr>
                </template>
                <template v-else>
                    <b-tr>
                      <b-td colspan="7" class="text-center">{{ $t('globalTrans.no_data_found') }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-overlay>
          </div>
        </template>
      </body-card>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { exGardenSaleReportApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
export default {
  name: 'AnnualProduction',
  components: {
    ListReportHead
  },
  props: ['item'],
  data () {
    return {
      // baseUrl: teaGardenServiceBaseUrl,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      search: {
        fiscal_year_id: 0,
        fiscal_year_ids: []
      },
      resultData: [],
      showData: false,
      firstYearQty: 0,
      firstYearAvgPrice: 0,
      secondYearQty: 0,
      secondYearAvgPrice: 0,
      thirdYearQty: 0,
      thirdYearAvgPrice: 0
    }
  },
  created () {
    this.getCurrentFiscalYear()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    yearList () {
      return helpers.getYearList()
    },
    monthList () {
      return this.$store.state.commonObj.monthList.map(item => {
        return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
      })
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.previousTwoYearFiscalYearId(newVal)
        }
      }
  },
  methods: {
    getCurrentFiscalYear () {
      const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          this.search.fiscal_year_id = element.value
        }
      })
    },
    async searchData () {
      this.resultData = []
      this.showData = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, exGardenSaleReportApi, this.search)
      if (result.success) {
        this.resultData = result.data
      } else {
        this.resultData = []
      }
      this.totatExportCount()
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getFiscalYearName (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    previousTwoYearFiscalYearId (id) {
      const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
      const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
      const fiscalYearIds = []
      fiscalYearListSort.forEach(item => {
        if (fiscalYearIds.length < 3) {
          fiscalYearIds.push(item.value)
        }
      })
      this.search.fiscal_year_ids = fiscalYearIds
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, exGardenSaleReportApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    totatExportCount () {
        this.firstYearQty = 0
        this.firstYearAvgPrice = 0
        this.secondYearQty = 0
        this.secondYearAvgPrice = 0
        this.thirdYearQty = 0
        this.thirdYearAvgPrice = 0
        this.resultData.map(item => {
          item.fiscal_year_info.map(country => {
            if (parseInt(country.fiscal_year_id) === this.search.fiscal_year_id) {
              this.firstYearQty += country.total_quantity
              this.firstYearAvgPrice += country.total_avg_price
            }
            if (parseInt(country.fiscal_year_id) === this.search.fiscal_year_ids[1]) {
              this.secondYearQty += country.total_quantity
              this.secondYearAvgPrice += country.total_avg_price
            }
            if (parseInt(country.fiscal_year_id) === this.search.fiscal_year_ids[2]) {
              this.thirdYearQty += country.total_quantity
              this.thirdYearAvgPrice += country.total_avg_price
            }
          })
        })
      }
  }
}
</script>
