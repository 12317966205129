<template>
    <b-row>
      <b-col lg="12" sm="12">
        <template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                    </template>
                  <b-form-select
                      plain
                      id="fiscal_year_id"
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                  <b-form-group label-for="month" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                        {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        id="month"
                        v-model="search.month"
                        :options="monthList"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <ValidationProvider name="Exporter Name" vid="export_company_id" rules="">
                  <b-form-group label-for="export_company_id" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                        {{ $t('teaGardenService.exporter_name') }}
                    </template>
                    <b-form-select
                        plain
                        id="export_company_id"
                        v-model="search.export_company_id"
                        :options="exportCompanyList"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col>
                <b-button type="submit" size="sm" variant="primary" class="mt-20" style="font-size:13px">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
              <b-col class="text-right" v-if="buttonPdf">
                <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                  <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
        </template>
        <body-card>
          <template v-slot:body>
            <div v-if="showData">
            <b-overlay :show="loader">
              <b-row>
                <b-col>
                  <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    {{ $t('teaGardenService.exporter_export') }} {{ $t('globalTrans.report') }}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row class="justify-content-center mb-4">
                <b-col md="6" class="text-center">
                  <span>{{ $t('globalTrans.fiscal_year') }}: </span> {{ getFiscalYearName(search.fiscal_year_id) }}
                  <br>
                  <span> {{ $t('globalTrans.month') }} : </span> {{ getMonthList(search.month) }}
                  <br>
                  <span> {{ $t('teaGardenService.exporter_name') }} : </span> {{ search.export_company_id ? getCompanyName(search.export_company_id) : $t('globalTrans.all') }}
                  <br>
                  (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                  <br>
                (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                <br>
                (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
                </b-col>
              </b-row>
              <template v-if="reportData.length">
                <b-table-simple bordered small responsive>
                  <b-thead>
                    <b-tr>
                      <b-th rowspan="5" style="font-size: 12px;">{{ $t('teaGardenService.name_of_the_exporters') }}</b-th>
                      <b-th colspan="6" class="text-center" style="font-size: 12px;">
                          {{ currentLocale === 'bn' ? ' "' + getMonthList(search.month) +  ', ' + getFiscalYearName(search.fiscal_year_id) + '"'+ ' মাসে রপ্তানি' : 'Export During the Month' + ' "' + getMonthList(search.month) + ' , ' + getFiscalYearName(search.fiscal_year_id) + '"' }}
                      </b-th>
                      <b-th colspan="6" class="text-center" style="font-size: 12px;">
                          {{ currentLocale === 'bn' ? ' "' + getMonthList(search.month) +  ', ' + getFiscalYearName(search.fiscal_year_id) + '"'+ ' মাস পর্যন্ত ক্রমবর্ধমান রপ্তানি' : 'Cumulative Export up to the month' + ' "' + getMonthList(search.month) + ' , ' + getFiscalYearName(search.fiscal_year_id) + '"' }}
                      </b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th colspan="2" style="font-size: 12px">{{ $t('teaGardenService.loose_tea') }}</b-th>
                      <b-th colspan="2" style="font-size: 12px">{{ $t('teaGardenService.packet_tea') }}</b-th>
                      <b-th colspan="2" style="font-size: 12px">{{ $t('globalTrans.total') }}</b-th>
                      <b-th colspan="2" style="font-size: 12px">{{ $t('teaGardenService.loose_tea') }}</b-th>
                      <b-th colspan="2" style="font-size: 12px">{{ $t('teaGardenService.packet_tea') }}</b-th>
                      <b-th colspan="2" style="font-size: 12px">{{ $t('globalTrans.total') }}</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th rowspan="2" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th colspan="1" style="font-size: 12px;">{{ $t('teaGardenService.value_tk') }}</b-th>
                      <b-th rowspan="2" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th colspan="1" style="font-size: 12px;">{{ $t('teaGardenService.value_tk') }}</b-th>
                      <b-th rowspan="2" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th colspan="1" style="font-size: 12px;">{{ $t('teaGardenService.value_tk') }}</b-th>
                      <b-th rowspan="2" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th colspan="1" style="font-size: 12px;">{{ $t('teaGardenService.value_tk') }}</b-th>
                      <b-th rowspan="2" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th colspan="1" style="font-size: 12px;">{{ $t('teaGardenService.value_tk') }}</b-th>
                      <b-th rowspan="2" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th colspan="1" style="font-size: 12px;">{{ $t('teaGardenService.value_tk') }}</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th style="font-size: 12px;">{{ $t('teaGardenService.fob') }}</b-th>
                      <b-th style="font-size: 12px;">{{ $t('teaGardenService.fob') }}</b-th>
                      <b-th style="font-size: 12px;">{{ $t('teaGardenService.fob') }}</b-th>
                      <b-th style="font-size: 12px;">{{ $t('teaGardenService.fob') }}</b-th>
                      <b-th style="font-size: 12px;">{{ $t('teaGardenService.fob') }}</b-th>
                      <b-th style="font-size: 12px;">{{ $t('teaGardenService.fob') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td class="text-center">{{ $n(1) }}</b-td>
                      <b-td class="text-center">{{ $n(2) }}</b-td>
                      <b-td class="text-center">{{ $n(3) }}</b-td>
                      <b-td class="text-center">{{ $n(4) }}</b-td>
                      <b-td class="text-center">{{ $n(5)}}</b-td>
                      <b-td class="text-center">{{ $n(6) }}</b-td>
                      <b-td class="text-center">{{ $n(7) }}</b-td>
                      <b-td class="text-center">{{ $n(8) }}</b-td>
                      <b-td class="text-center">{{ $n(9) }}</b-td>
                      <b-td class="text-center">{{ $n(10) }}</b-td>
                      <b-td class="text-center">{{ $n(11) }}</b-td>
                      <b-td class="text-center">{{ $n(12) }}</b-td>
                      <b-td class="text-center">{{ $n(13)}}</b-td>
                    </b-tr>
                    <tr v-for="(item, index) in reportData" :key="index" class="text-center">
                      <td>{{ getCompanyName(item.export_company_id) }}</td>
                      <td>{{ item.current_month_loose_tea_quantity ? $n(item.current_month_loose_tea_quantity, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.current_month_loose_tea_fob_value ? $n(item.current_month_loose_tea_fob_value, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.current_month_packet_tea_quantity ? $n(item.current_month_packet_tea_quantity, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.current_month_packet_tea_fob_value ? $n(item.current_month_packet_tea_fob_value, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.current_month_total_quantity ? $n(item.current_month_total_quantity, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.current_month_total_fob_value ? $n(item.current_month_total_fob_value, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.upto_month_loose_tea_quantity ? $n(item.upto_month_loose_tea_quantity, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.upto_month_loose_tea_fob_value ? $n(item.upto_month_loose_tea_fob_value, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.upto_month_packet_tea_quantity ? $n(item.upto_month_packet_tea_quantity, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.upto_month_packet_tea_fob_value ? $n(item.upto_month_packet_tea_fob_value, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.upto_month_total_quantity ? $n(item.upto_month_total_quantity, { useGrouping: false }) : '' }}</td>
                      <td>{{ item.upto_month_total_fob_value ? $n(item.upto_month_total_fob_value, { useGrouping: false }) : '' }}</td>
                    </tr>
                    <b-tr class="text-center">
                        <b-td>{{ $t('globalTrans.total') }}</b-td>
                        <b-td> {{ current_month_loose_tea_total_qty ? $n(current_month_loose_tea_total_qty, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ current_month_loose_tea_total_value ? $n(current_month_loose_tea_total_value, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ current_month_packet_tea_total_qty ? $n(current_month_packet_tea_total_qty, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ current_month_packet_tea_fob_qty ? $n(current_month_packet_tea_fob_qty, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ current_month_total_quantity_grant_total ? $n(current_month_total_quantity_grant_total, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ current_month_total_fob_value_grant_total ? $n(current_month_total_fob_value_grant_total, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ upto_month_loose_tea_total_qty ? $n(upto_month_loose_tea_total_qty, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ upto_month_loose_tea_total_value ? $n(upto_month_loose_tea_total_value, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ upto_month_packet_tea_total_qty ? $n(upto_month_packet_tea_total_qty, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ upto_month_packet_tea_fob_qty ? $n(upto_month_packet_tea_fob_qty, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ upto_month_total_quantity_grant_total ? $n(upto_month_total_quantity_grant_total, { useGrouping: false }) : '' }} </b-td>
                        <b-td> {{ upto_month_total_fob_value_grant_total ? $n(upto_month_total_fob_value_grant_total, { useGrouping: false }) : '' }} </b-td>
                      </b-tr>
                  </b-tbody>
                </b-table-simple>
              </template>
              <template v-else>
                <div class="text-center text-danger mt-5">
                  {{ $t('globalTrans.noDataFound') }}
                </div>
              </template>
            </b-overlay>
            </div>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { exporterExportReportApi } from '../../api/routes'
  import { helpers } from '@/utils/helper-functions'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  export default {
  name: 'ExportersAndExport',
  components: {
    ListReportHead
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      search: {
        fiscal_year_id: 0,
        month: new Date().getMonth() + 1,
        export_company_id: 0
      },
      showData: false,
      loader: false,
      reportData: [],
      buttonPdf: false
    }
  },
  created () {
    this.getCurrentFiscalYear()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    yearList () {
      return helpers.getYearList()
    },
    monthList () {
    return this.$store.state.commonObj.monthList.map(item => {
      return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
      })
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    exportCompanyList () {
      return this.$store.state.TeaGardenService.commonObj.masterExportCompanyList.filter(item => item.status === 1)
    }
  },
  methods: {
    getCurrentFiscalYear () {
      const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          this.search.fiscal_year_id = element.value
        }
      })
    },
    async pdfExport () {
      this.loader = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, exporterExportReportApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.loader = false
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async searchData () {
      this.reportData = []
      this.showData = true
      this.loader = true
      this.buttonPdf = true
      const result = await RestApi.getData(teaGardenServiceBaseUrl, exporterExportReportApi, this.search)
      if (result.success) {
        this.reportData = result.data
        this.ExportDataGrantTotal()
      } else {
        this.reportData = []
      }
      this.loader = false
    },
    getFiscalYearName (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getCompanyName (companyId) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterExportCompanyList.find(ele => ele.value === parseInt(companyId))
      if (typeof obj !== 'undefined') {
        return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
      } else {
        return ''
      }
    },
    getMonthList (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
      if (typeof obj !== 'undefined') {
        return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
      } else {
        return ''
      }
    },
    ExportDataGrantTotal () {
      this.current_month_loose_tea_total_qty = 0
      this.current_month_loose_tea_total_value = 0
      this.current_month_packet_tea_total_qty = 0
      this.current_month_packet_tea_fob_qty = 0
      this.current_month_total_quantity_grant_total = 0
      this.current_month_total_fob_value_grant_total = 0
      this.upto_month_loose_tea_total_qty = 0
      this.upto_month_loose_tea_total_value = 0
      this.upto_month_packet_tea_total_qty = 0
      this.upto_month_packet_tea_fob_qty = 0
      this.upto_month_total_quantity_grant_total = 0
      this.upto_month_total_fob_value_grant_total = 0
      this.reportData.map(item => {
          this.current_month_loose_tea_total_qty += parseInt(item.current_month_loose_tea_quantity)
          this.current_month_loose_tea_total_value += parseInt(item.current_month_loose_tea_fob_value)
          this.current_month_packet_tea_total_qty += parseInt(item.current_month_packet_tea_quantity)
          this.current_month_packet_tea_fob_qty += parseInt(item.current_month_packet_tea_fob_value)
          this.current_month_total_quantity_grant_total += parseInt(item.current_month_total_quantity)
          this.current_month_total_fob_value_grant_total += parseInt(item.current_month_total_fob_value)
          this.upto_month_loose_tea_total_qty += parseInt(item.upto_month_loose_tea_quantity)
          this.upto_month_loose_tea_total_value += parseInt(item.upto_month_loose_tea_fob_value)
          this.upto_month_packet_tea_total_qty += parseInt(item.upto_month_packet_tea_quantity)
          this.upto_month_packet_tea_fob_qty += parseInt(item.upto_month_packet_tea_fob_value)
          this.upto_month_total_quantity_grant_total += parseInt(item.upto_month_total_quantity)
          this.upto_month_total_fob_value_grant_total += parseInt(item.upto_month_total_fob_value)
      })
    }
  }
  }
  </script>
