<template>
  <b-row>
    <b-col lg="12" sm="12">
      <template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <ValidationProvider name="Year" vid="fiscal_year_id" rules="">
                  <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('globalTrans.fiscal_year') }}
                    </template>
                    <b-form-select
                      plain
                      id="fiscal_year_id"
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <b-button type="submit" size="sm" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="text-right" v-if="showData">
                  <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                    <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                  </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <body-card>
        <template v-slot:body>
          <div v-if="showData">
          <b-overlay :show="loading">
            <b-row>
              <b-col>
                <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                  <template v-slot:projectNameSlot>
                    {{ }}
                  </template>
                  {{ $t('teaGardenService.annual_export') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row class="justify-content-center mb-4">
              <b-col md="6" class="text-center">
                <span>{{ $t('globalTrans.fiscal_year') }}:</span> {{ search.fiscal_year_id > 0 ? getFiscalYearName(search.fiscal_year_id) : $t('globalTrans.all') }} <br>
                (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                <br>
                (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                <br>
                (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
              </b-col>
            </b-row>
            <b-table-simple bordered small responsive>
              <b-thead>
                <b-tr class="text-center">
                  <b-th rowspan="2" class="text-center">{{ $t('teaGardenService.fiscal_year') }}</b-th>
                  <b-th colspan="3">{{ $t('teaGardenService.loose_tea') }}</b-th>
                  <b-th colspan="3">{{ $t('teaGardenService.value_added_tea') }}</b-th>
                  <b-th colspan="3">{{ $t('globalTrans.total') }}</b-th>
                </b-tr>
                <b-tr class="text-center">
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.avg_price_kg') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value_us') }}</b-th>
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.avg_price_kg') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value_us') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value_fob') }}</b-th>
                  <b-th>{{ $t('teaGardenService.avg_price_kg') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value_us') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="resultData.length">
                  <b-tr v-for="(item, index) in resultData" :key="index">
                    <b-td>{{ getFiscalYearName(item.fiscal_year_id) }}</b-td>
                    <b-td>
                      {{ item.upto_month_loose_tea_quantity > 0 ? $n(item.upto_month_loose_tea_quantity, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_loose_tea_avg_price > 0 ? $n(item.upto_month_loose_tea_avg_price, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_loose_tea_value > 0 ? $n(item.upto_month_loose_tea_value, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_packet_tea_quantity > 0 ? $n(item.upto_month_packet_tea_quantity, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_packet_tea_avg_price > 0 ? $n(item.upto_month_packet_tea_avg_price, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_packet_tea_value > 0 ? $n(item.upto_month_packet_tea_value, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_total_quantity > 0 ? $n(item.upto_month_total_quantity, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_total_avg_price > 0 ? $n(item.upto_month_total_avg_price, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ item.upto_month_total_value > 0 ? $n(item.upto_month_total_value, { useGrouping: false }) : '' }}
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td colspan="10" class="text-center">{{ $t('globalTrans.no_data_found') }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
          </div>
        </template>
      </body-card>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { annualExportReportApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
export default {
name: 'AnnualExport',
components: {
  ListReportHead
},
props: ['item'],
data () {
return {
  teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
  search: {
    fiscal_year_id: 0,
    fiscal_year_ids: [],
    fiscal_year_id_prev: 0
  },
  resultData: [],
  showData: false,
  loose_tea_tot_qty: 0,
  loose_tea_tot_val: 0,
  packet_tea_tot_qty: 0,
  packet_tea_tot_val: 0,
  upto_month_total_quantity: 0,
  upto_month_total_fob_value: 0,
  loose_tea_tot_qty_prev: 0,
  loose_tea_tot_val_prev: 0,
  packet_tea_tot_qty_prev: 0,
  packet_tea_tot_val_prev: 0,
  total_upto_month_quantity_prev: 0,
  total_upto_month_value_prev: 0
}
},
created () {
  this.getCurrentFiscalYear()
},
watch: {
  'search.fiscal_year_id': function (newVal, oldVal) {
    if (newVal !== oldVal) {
      this.previousTwoYearFiscalYearId(newVal)
    }
  }
},
computed: {
  currentLocale () {
    return this.$i18n.locale
  },
  loading () {
    return this.$store.state.commonObj.loading
  },
  yearList () {
    return helpers.getYearList()
  },
  monthList () {
    return this.$store.state.commonObj.monthList.map(item => {
      return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
    })
  },
  fiscalYearList () {
    return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
  },
  masterTeaCompanyList () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.filter(item => item.status === 1)
  },
  teaCompanyNameList () {
    return this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.filter(item => item.status === 1)
  }
},
methods: {
  getCurrentFiscalYear () {
    const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
    const currentDate = new Date()
    const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
    const yearPosition = month < 7 ? 5 : 0
    const yearStr = `${year}`
    fiscalYearList.forEach(element => {
      if (element.text_en.indexOf(yearStr) === yearPosition) {
        this.search.fiscal_year_id = element.value
      }
    })
  },
  async searchData () {
    this.resultData = []
    this.showData = true
    this.$store.dispatch('mutateCommonProperties', { loading: true })
    const result = await RestApi.getData(teaGardenServiceBaseUrl, annualExportReportApi, this.search)
    if (result.success) {
      this.resultData = result.data
    } else {
      this.resultData = []
    }
    this.$store.dispatch('mutateCommonProperties', { loading: false })
  },
  getFiscalYearName (id) {
    const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
    if (typeof data !== 'undefined') {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    } else {
      return ''
    }
  },
  getCompanyName (id) {
    const obj = this.$store.state.TeaGardenService.commonObj.masterTeaCompanyList.find(item => item.value === parseInt(id))
    return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
  },
  previousTwoYearFiscalYearId (id) {
    const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
    const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
    const fiscalYearIds = []
    fiscalYearListSort.forEach(item => {
      if (fiscalYearIds.length < 2) {
        if (fiscalYearIds.length === 1) {
          this.search.fiscal_year_id_prev = item.value
        }
        fiscalYearIds.push(item.value)
      }
    })
    this.search.fiscal_year_ids = fiscalYearIds
  },
  async pdfExport () {
    const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
    const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
    if (service !== undefined) {
      if (service.office_type_id) {
        params.office_type_id = service.office_type_id
      }
      if (service.office_id) {
        params.office_id = service.office_id
      }
    }
    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, annualExportReportApi, params)
    var blob = new Blob([result], {
      type: 'application/pdf'
    })
    var url = window.URL.createObjectURL(blob)
    window.open(url).print()
    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
  }
}
}
</script>
