<template>
  <b-row>
    <b-col lg="12" sm="12">
      <template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <ValidationProvider name="Year" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      id="fiscal_year_id"
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <ValidationProvider name="Year" vid="month" rules="required|min_value:1">
                  <b-form-group label-for="month" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      id="month"
                      v-model="search.month"
                      :options="monthList"
                      :state="errors[0] ? false : (valid ? true : null)"
                    >
                      <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <ValidationProvider name="country" vid="country_id" rules="">
                <b-form-group label-for="country_id" slot-scope="{ valid, errors }">
                  <template v-slot:label>
                      {{ $t('globalTrans.country') }}
                  </template>
                  <b-form-select
                      plain
                      id="country_id"
                      v-model="search.country_id"
                      :options="countryList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <b-button type="submit" size="sm" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="text-right" v-if="showData">
                <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                  <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <body-card>
        <template v-slot:body>
          <div v-if="showData">
          <b-overlay :show="loading">
            <b-row>
              <b-col>
                <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                  <template v-slot:projectNameSlot>
                    {{ }}
                  </template>
                  {{ $t('teaGardenService.country_wise_export') }} {{ $t('globalTrans.report') }}
                </list-report-head>
              </b-col>
            </b-row>
            <b-row class="justify-content-center mb-4">
              <b-col md="6" class="text-center">
                <span>{{ $t('globalTrans.fiscal_year') }}:</span> {{ getFiscalYearName(search.fiscal_year_id) }}  <span class="ml-md-5">{{ $t('globalTrans.month') }}:</span> {{ getMonthName(search.month) }} <span class="ml-md-5">{{ $t('globalTrans.country') }} : </span> {{ search.country_id ? getCountryName(search.country_id) : $t('globalTrans.all') }} <br>
                (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                <br>
                (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                <br>
                (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
              </b-col>
            </b-row>
            <b-table-simple bordered small responsive>
              <b-thead>
                <b-tr>
                  <b-th rowspan="3">{{ $t('teaGardenService.name_of_the_country') }}</b-th>
                  <template v-if="currentLocale === 'en'">
                    <b-th colspan="6" class="text-center">{{ $t('teaGardenService.export_up_to') }} {{ getMonthList(search.month) }} , {{ '(' + getFiscalYearName(search.fiscal_year_id) + ') ' }} </b-th>
                    <b-th colspan="6" class="text-center">{{ $t('teaGardenService.export_up_to') }} {{ getMonthList(search.month) }} , {{ '(' + getFiscalYearName(search.fiscal_year_id_prev) + ') ' }}</b-th>
                  </template>
                  <template v-if="currentLocale === 'bn'">
                    <b-th colspan="6" class="text-center">{{ getMonthList(search.month) }} {{ $t('teaGardenService.export_up_to') }}, {{ '(' + getFiscalYearName(search.fiscal_year_id) + ') ' }} </b-th>
                    <b-th colspan="6" class="text-center">{{ getMonthList(search.month) }} {{ $t('teaGardenService.export_up_to') }}, {{ '(' + getFiscalYearName(search.fiscal_year_id_prev) + ') ' }}</b-th>
                  </template>
                </b-tr>
                <b-tr class="text-center">
                  <b-th colspan="2">{{ $t('teaGardenService.loose_tea') }}</b-th>
                  <b-th colspan="2">{{ $t('teaGardenService.packet_tea') }}</b-th>
                  <b-th colspan="2">{{ $t('globalTrans.total') }}</b-th>
                  <b-th colspan="2">{{ $t('teaGardenService.loose_tea') }}</b-th>
                  <b-th colspan="2">{{ $t('teaGardenService.packet_tea') }}</b-th>
                  <b-th colspan="2">{{ $t('globalTrans.total') }}</b-th>
                </b-tr>
                <b-tr class="text-center">
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value') }}</b-th>
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value') }}</b-th>
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value') }}</b-th>
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value') }}</b-th>
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value') }}</b-th>
                  <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                  <b-th>{{ $t('teaGardenService.value') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <template v-if="resultData.length">
                  <b-tr v-for="(item, index) in countryList" :key="'year-' + index">
                    <b-td>{{ getCountryName(item.value) }}</b-td>
                    <template v-for="(detailsItem, detailsIndex) in resultData">
                      <b-td :key="'dm-' + detailsIndex">
                        <template v-if="detailsItem.countries[item.value]">
                          <template v-if="detailsItem.countries[item.value].upto_month_loose_tea_quantity">
                            {{ $n(detailsItem.countries[item.value].upto_month_loose_tea_quantity, { useGrouping: false }) }}
                          </template>
                        </template>
                      </b-td>
                      <b-td :key="'dm-' + detailsIndex">
                        <template v-if="detailsItem.countries[item.value]">
                          <template v-if="detailsItem.countries[item.value].upto_month_loose_tea_fob_value">
                            {{ $n(detailsItem.countries[item.value].upto_month_loose_tea_fob_value, { useGrouping: false }) }}
                          </template>
                        </template>
                      </b-td>
                      <b-td :key="'dm-' + detailsIndex">
                        <template v-if="detailsItem.countries[item.value]">
                          <template v-if="detailsItem.countries[item.value].upto_month_packet_tea_quantity">
                            {{ $n(detailsItem.countries[item.value].upto_month_packet_tea_quantity, { useGrouping: false }) }}
                          </template>
                        </template>
                      </b-td>
                      <b-td :key="'dm-' + detailsIndex">
                        <template v-if="detailsItem.countries[item.value]">
                          <template v-if="detailsItem.countries[item.value].upto_month_packet_tea_fob_value">
                            {{ $n(detailsItem.countries[item.value].upto_month_packet_tea_fob_value, { useGrouping: false }) }}
                          </template>
                        </template>
                      </b-td>
                      <b-td :key="'dm-' + detailsIndex">
                        <template v-if="detailsItem.countries[item.value]">
                          <template v-if="detailsItem.countries[item.value].upto_month_total_quantity">
                            {{ $n(detailsItem.countries[item.value].upto_month_total_quantity, { useGrouping: false }) }}
                          </template>
                        </template>
                      </b-td>
                      <b-td :key="'dm-' + detailsIndex">
                        <template v-if="detailsItem.countries[item.value]">
                          <template v-if="detailsItem.countries[item.value].upto_month_total_fob_value">
                            {{ $n(detailsItem.countries[item.value].upto_month_total_fob_value, { useGrouping: false }) }}
                          </template>
                        </template>
                      </b-td>
                    </template>
                  </b-tr>
                <b-tr class="text-center" style="font-weight: bold;">
                  <b-td>{{ $t('globalTrans.total') }}</b-td>
                  <b-td> {{ $n(loose_tea_tot_qty, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(loose_tea_tot_val, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(packet_tea_tot_qty, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(packet_tea_tot_val, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(upto_month_total_quantity, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(upto_month_total_fob_value, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(loose_tea_tot_qty_prev, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(loose_tea_tot_val_prev, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(packet_tea_tot_qty_prev, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(packet_tea_tot_val_prev, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(total_upto_month_quantity_prev, { useGrouping: false }) }} </b-td>
                  <b-td> {{ $n(total_upto_month_value_prev, { useGrouping: false }) }} </b-td>
                </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td colspan="14" class="text-center">{{ $t('globalTrans.no_data_found') }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
          </div>
        </template>
      </body-card>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { countryWiseExportReportApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
export default {
name: 'AnnualProduction',
components: {
  ListReportHead
},
props: ['item'],
data () {
  return {
    // baseUrl: teaGardenServiceBaseUrl,
    teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
    search: {
      fiscal_year_id: 0,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      fiscal_year_ids: [],
      country_id: 0,
      fiscal_year_id_prev: 0
    },
    detailsData: [],
    resultData: [],
    loose_tea_tot_qty: 0,
    loose_tea_tot_val: 0,
    packet_tea_tot_qty: 0,
    packet_tea_tot_val: 0,
    upto_month_total_quantity: 0,
    upto_month_total_fob_value: 0,
    loose_tea_tot_qty_prev: 0,
    loose_tea_tot_val_prev: 0,
    packet_tea_tot_qty_prev: 0,
    packet_tea_tot_val_prev: 0,
    total_upto_month_quantity_prev: 0,
    total_upto_month_value_prev: 0,
    showData: false
  }
},
created () {
  this.getCurrentFiscalYear()
},
computed: {
  currentLocale () {
    return this.$i18n.locale
  },
  loading () {
    return this.$store.state.commonObj.loading
  },
  yearList () {
    return helpers.getYearList()
  },
  monthList () {
    return this.$store.state.commonObj.monthList.map(item => {
      return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
    })
  },
  fiscalYearList () {
    return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
  },
  countryList () {
    return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
  }
},
watch: {
  'search.fiscal_year_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.previousTwoYearFiscalYearId(newVal)
      }
    }
  },
  methods: {
    getMonthList (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
      if (typeof obj !== 'undefined') {
        return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
      } else {
        return ''
      }
    },
    getCurrentFiscalYear () {
      const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          this.search.fiscal_year_id = element.value
        }
      })
    },
    async searchData () {
      this.resultData = []
      this.showData = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, countryWiseExportReportApi, this.search)
      if (result.success) {
        this.resultData = result.data
      } else {
        this.resultData = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
      this.totatExportCount()
    },
    getFiscalYearName (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getCountryName (id) {
      const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(id))
      if (obj !== undefined) {
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      } else {
        return ''
      }
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, countryWiseExportReportApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    previousTwoYearFiscalYearId (id) {
      const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
      const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
      const fiscalYearIds = []
      fiscalYearListSort.forEach(item => {
        if (fiscalYearIds.length < 2) {
          if (fiscalYearIds.length === 1) {
            this.search.fiscal_year_id_prev = item.value
          }
          fiscalYearIds.push(item.value)
        }
      })
      this.search.fiscal_year_ids = fiscalYearIds
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    totatExportCount () {
      this.loose_tea_tot_qty = 0
      this.loose_tea_tot_val = 0
      this.packet_tea_tot_qty = 0
      this.packet_tea_tot_val = 0
      this.upto_month_total_quantity = 0
      this.upto_month_total_fob_value = 0
      this.loose_tea_tot_qty_prev = 0
      this.loose_tea_tot_val_prev = 0
      this.packet_tea_tot_qty_prev = 0
      this.packet_tea_tot_val_prev = 0
      this.total_upto_month_quantity_prev = 0
      this.total_upto_month_value_prev = 0
      this.resultData.map(item => {
        Object.values(item.countries).map(country => {
          if (country !== null) {
            if (this.search.fiscal_year_id === parseInt(item.fiscal_year_id)) {
              this.loose_tea_tot_qty += parseInt(country.upto_month_loose_tea_quantity)
              this.loose_tea_tot_val += parseInt(country.upto_month_loose_tea_fob_value)
              this.packet_tea_tot_qty += parseInt(country.upto_month_packet_tea_quantity)
              this.packet_tea_tot_val += parseInt(country.upto_month_packet_tea_fob_value)
              this.upto_month_total_quantity += parseInt(country.upto_month_total_quantity)
              this.upto_month_total_fob_value += parseInt(country.upto_month_total_fob_value)
            } else {
              this.loose_tea_tot_qty_prev += parseInt(country.upto_month_loose_tea_quantity)
              this.loose_tea_tot_val_prev += parseInt(country.upto_month_loose_tea_fob_value)
              this.packet_tea_tot_qty_prev += parseInt(country.upto_month_packet_tea_quantity)
              this.packet_tea_tot_val_prev += parseInt(country.upto_month_packet_tea_fob_value)
              this.total_upto_month_quantity_prev += parseInt(country.upto_month_total_quantity)
              this.total_upto_month_value_prev += parseInt(country.upto_month_total_fob_value)
            }
          }
        })
      })
    }
  }
}
</script>
