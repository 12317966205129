<template>
    <b-row>
      <b-col lg="12" sm="12">
        <template>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                        plain
                        id="fiscal_year_id"
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                  <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                    <b-form-group label-for="month" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                        plain
                        id="month"
                        v-model="search.month"
                        :options="monthList"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                  <ValidationProvider name="Aucation Location" vid="auction_location" rules="required|min_value:1">
                    <b-form-group label-for="auction_location" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('teaGardenService.auction_location') }} <span class="text-danger">*</span>
                      </template>
                    <b-form-select
                        plain
                        id="auction_location"
                        v-model="search.auction_location"
                        :options="locationList"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col>
                  <b-button type="submit" size="sm" variant="primary" class="mt-20" style="font-size:15px">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
                <b-col class="text-right" v-if="pdfButton">
                  <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                    <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </template>
        <body-card>
          <template v-slot:body>
            <div v-if="showData">
              <b-overlay :show="loading">
                <b-row>
                  <b-col>
                    <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                      <template v-slot:projectNameSlot>
                        {{ }}
                      </template>
                      {{ $t('teaGardenService.export_internal_report') }}
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row class="justify-content-center mb-4 mt-3">
                  <b-col md="6" class="text-center">
                    <span>{{ $t('globalTrans.fiscal_year') }}: </span> {{ getFiscalYearName(search.fiscal_year_id) }}
                    <br>
                    <span> {{ $t('globalTrans.month') }} : </span> {{ getMonthName(search.month) }}
                    <br>
                     <span>  {{ $t('teaGardenService.auction_location') }} : </span> {{ getLocationName(search.auction_location) }}
                    <br>
                    (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                    <br>
                    (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                    <br>
                    (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
                  </b-col>
                </b-row>
                <template v-if="detailsData.length">
                  <b-table-simple bordered small responsive>
                    <b-thead>
                      <b-tr>
                          <b-th rowspan="3" class="text-center" style="font-size: 12px;">{{ $t('globalTrans.fiscal_year') }}</b-th>
                          <b-th colspan="6" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.during_month') + ' "' }}{{ getMonthName(search.month) }} , {{ getFiscalYearName(search.fiscal_year_id) + '" ' }} </b-th>
                          <b-th colspan="6" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.upto_month') + ' "' }}{{ getMonthName(search.month) }} , {{ getFiscalYearName(search.fiscal_year_id) + '" ' }}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th colspan="2" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.iner_account') }}</b-th>
                          <b-th colspan="2" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.export_account') }}</b-th>
                          <b-th colspan="2" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.total') }}</b-th>
                          <b-th colspan="2" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.iner_account') }}</b-th>
                          <b-th colspan="2" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.export_account') }}</b-th>
                          <b-th colspan="2" class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.total') }}</b-th>
                      </b-tr>
                      <b-tr>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.value') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.value') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.value') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.value') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.value') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.qty') }}</b-th>
                          <b-th class="text-center" style="font-size: 12px;">{{ $t('teaGardenService.value') }}</b-th>
                      </b-tr>
                      </b-thead>
                        <b-tbody>
                          <b-tr>
                            <b-td class="text-center">{{ $n(1) }}</b-td>
                            <b-td class="text-center">{{ $n(2) }}</b-td>
                            <b-td class="text-center">{{ $n(3) }}</b-td>
                            <b-td class="text-center">{{ $n(4) }}</b-td>
                            <b-td class="text-center">{{ $n(5) }}</b-td>
                            <b-td class="text-center">{{ $n(6) }}</b-td>
                            <b-td class="text-center">{{ $n(7) }}</b-td>
                            <b-td class="text-center">{{ $n(8) }}</b-td>
                            <b-td class="text-center">{{ $n(9) }}</b-td>
                            <b-td class="text-center">{{ $n(10) }}</b-td>
                            <b-td class="text-center">{{ $n(11) }}</b-td>
                            <b-td class="text-center">{{ $n(12) }}</b-td>
                            <b-td class="text-center">{{ $n(13) }}</b-td>
                          </b-tr>
                          <b-tr v-for="(item, index) in detailsData" :key="index">
                            <b-td class="text-center">{{ getFiscalYearName(item.fiscal_year_id )}}</b-td>
                            <b-td class="text-center">{{ item.current_month_internal_account ? $n(item.current_month_internal_account, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.current_month_internal_account_value ? $n(item.current_month_internal_account_value, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.current_month_auction_export_account ? $n(item.current_month_auction_export_account, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.current_month_auction_export_account_value ? $n(item.current_month_auction_export_account_value, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.current_month_total_quantity ? $n(item.current_month_total_quantity, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.current_month_total_quantity_value ? $n(item.current_month_total_quantity_value, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.upto_month_internal_account ? $n(item.upto_month_internal_account, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.upto_month_internal_account_value ? $n(item.upto_month_internal_account_value, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.upto_month_auction_export_account ? $n(item.upto_month_auction_export_account, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.upto_month_auction_export_account_value ? $n(item.upto_month_auction_export_account_value, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.upto_month_total_quantity ? $n(item.upto_month_total_quantity, { useGrouping: false }) : '' }}</b-td>
                            <b-td class="text-center">{{ item.upto_month_total_quantity_value ? $n(item.upto_month_total_quantity_value, { useGrouping: false }) : '' }}</b-td>
                          </b-tr>
                      </b-tbody>
                  </b-table-simple>
                </template>
                <template v-else>
                  <div class="text-center text-danger mt-5">
                    {{ $t('globalTrans.noDataFound') }}
                  </div>
                </template>
              </b-overlay>
            </div>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
  import { exportInternalApi } from '../../api/routes'
  import { helpers } from '@/utils/helper-functions'
  import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
  export default {
    name: 'ExportInternalAccount',
    props: ['item'],
    components: {
      ListReportHead
    },
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        search: {
          fiscal_year_ids: [],
          fiscal_year_id: 0,
          auction_location: 0,
          month: new Date().getMonth() + 1
        },
        pdfButton: false,
        detailsData: [],
        showData: false
      }
    },
    created () {
      this.getCurrentFiscalYear()
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      loading () {
        return this.$store.state.commonObj.loading
      },
      yearList () {
        return helpers.getYearList()
      },
      monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
          return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
        })
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      locationList () {
        return [
          {
            value: 1, text_en: 'Chattogram', text_bn: 'চট্টগ্রাম', text: this.currentLocale === 'en' ? 'Chattogram' : 'চট্টগ্রাম'
          },
          {
            value: 2, text_en: 'Sylhet', text_bn: 'সিলেট', text: this.currentLocale === 'en' ? 'Sylhet' : 'সিলেট'
          }
        ]
      }
    },
    watch: {
      'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.previousTwoYearFiscalYearId(newVal)
        }
      }
    },
    methods: {
      getCurrentFiscalYear () {
        const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        fiscalYearList.forEach(element => {
          if (element.text_en.indexOf(yearStr) === yearPosition) {
            this.search.fiscal_year_id = element.value
          }
        })
      },
      async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, exportInternalApi, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      async searchData () {
        this.detailsData = []
        this.showData = true
        this.pdfButton = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(teaGardenServiceBaseUrl, exportInternalApi, this.search)
        if (result.success) {
          this.detailsData = result.data
        } else {
          this.detailsData = []
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      },
      previousTwoYearFiscalYearId (id) {
        const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
        const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
        const fiscalYearIds = []
        fiscalYearListSort.forEach(item => {
          if (fiscalYearIds.length < 3) {
            fiscalYearIds.push(item.value)
          }
        })
        this.search.fiscal_year_ids = fiscalYearIds
      },
      getFiscalYearName (id) {
        const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
        if (typeof obj !== 'undefined') {
          return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
        } else {
          return ''
        }
      },
      getMonthName (id) {
        const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
        if (typeof obj !== 'undefined') {
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        } else {
          return ''
        }
      },
      getLocationName (id) {
        const obj = this.locationList.find(item => item.value === parseInt(id))
        if (typeof obj !== 'undefined') {
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        } else {
          return ''
        }
      }
    }
  }
  </script>
