<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenService.bulletin') }}</h4>
      </template>
      <template v-slot:searchBody>
        <div class="accordion" role="tablist">
          <!---Annual Production--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.annual-production variant="primary">{{ $t('teaGardenService.annual_production') }}</b-button>
            </b-card-header>
            <b-collapse id="annual-production" visible accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <AnnualProduction />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---Auction Report--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.auction-sale variant="primary">{{ $t('teaGardenService.auction_sale') }}</b-button>
            </b-card-header>
            <b-collapse id="auction-sale" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <AuctionSale />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---Export Internal Account--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.export-internal variant="primary">{{ $t('teaGardenService.export_internal_report') }}</b-button>
            </b-card-header>
            <b-collapse id="export-internal" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ExportInternalAccount />
              </b-card-body>
            </b-collapse>
          </b-card>
           <!---Country wise import Report--->
           <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.country-wise-import variant="primary">{{ $t('teaGardenService.country_wise_import') }}</b-button>
            </b-card-header>
            <b-collapse id="country-wise-import" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <CountryWiseImport />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---ExGardenSale Report--->
           <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.ex-garden-sale variant="primary">{{ $t('teaGardenService.ex_garden_sale') }}</b-button>
            </b-card-header>
            <b-collapse id="ex-garden-sale" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ExGardenSale />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---Country wise export Report--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.country-wise-export variant="primary">{{ $t('teaGardenService.country_wise_export') }}</b-button>
            </b-card-header>
            <b-collapse id="country-wise-export" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <CountryWiseExport />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---Exporters and Export Report--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.exporters_and_export variant="primary">{{ $t('teaGardenService.exporter_export') }}</b-button>
            </b-card-header>
            <b-collapse id="exporters_and_export" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ExportersAndExport />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---Comparison Exporters & Export Report--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.comparison-exporters-export variant="primary">{{ $t('teaGardenService.comparison_exporters_export') }}</b-button>
            </b-card-header>
            <b-collapse id="comparison-exporters-export" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <ComparisonExporters />
              </b-card-body>
            </b-collapse>
          </b-card>
          <!---Annual Export Report--->
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle.annual-export variant="primary">{{ $t('teaGardenService.annual_export') }}</b-button>
            </b-card-header>
            <b-collapse id="annual-export" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <AnnualExport />
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </template>
      <!-- search section end -->
    </card>
  </div>
</template>
<script>
  import AnnualProduction from './AnnualProduction'
  import AuctionSale from './AuctionSale.vue'
  import ExportInternalAccount from './ExportInternalAccount'
  import CountryWiseExport from './CountryWiseExport'
  import CountryWiseImport from './CountryWiseImport'
  import ExportersAndExport from './ExportersAndExport'
  import ComparisonExporters from './ComparisonExporters'
  import ExGardenSale from './ExGardenSale'
  import AnnualExport from './AnnualExport'
  export default {
    components: {
      AnnualProduction,
      ExportInternalAccount,
      AuctionSale,
      CountryWiseExport,
      CountryWiseImport,
      ExportersAndExport,
      ComparisonExporters,
      ExGardenSale,
      AnnualExport
    },
    data () {
      return {
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          auction_no: '',
          fiscal_year_id: 0,
          auction_date: '',
          auction_location: 0,
          broker_id: 0,
          limit: 10
        }
      }
    },
    created () {
    },
    watch: {
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
    }
  }
  </script>
  <style>
    .btn-success {
      background-color: var(--success) !important;
      border-color: var(--success);
    }
</style>
