<template>
    <b-row>
      <b-col lg="12" sm="12">
        <template>
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
              <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                    <b-form-group label-for="year" slot-scope="{ valid, errors }">
                      <template v-slot:label>
                        {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        id="year"
                        v-model="search.year"
                        :options="yearList"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback d-block">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-button type="submit" size="sm" variant="primary" class="mt-20" style="font-size:15px">
                    <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                  </b-button>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4" class="text-right" v-if="buttonPdf">
                  <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                    <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </template>
        <body-card>
          <template v-slot:body>
            <div v-if="showData">
              <b-overlay :show="loading">
                <b-row>
                  <b-col>
                    <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                      <template v-slot:projectNameSlot>
                        {{ }}
                      </template>
                      {{ $t('teaGardenService.annual_production_report') }}
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row class="justify-content-center mb-4 mt-4">
                  <b-col md="6" class="text-center">
                    <span>{{ $t('globalTrans.year') }}: </span> {{ $n(search.year, { useGrouping: false }) }} <br>
                    (<span>{{ $t('globalTrans.quantity') }}: </span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                    <br>
                    (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                    <br>
                    (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
                  </b-col>
                </b-row>
                <template>
                  <!--Annual Production Report-->
                  <template v-if="detailsData.length">
                    <b-table-simple bordered small responsive>
                      <b-tr>
                        <b-th>{{ $t('globalTrans.year') }}</b-th>
                        <template v-if="detailsData.length">
                          <b-td width="25%" v-for="(item, index) in detailsData" :key="'th1-' + index">
                            {{ $n(item.year, { useGrouping: false }) }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <b-th>{{ $t('teaGardenService.production_target') }}</b-th>
                        <template v-if="detailsData.length">
                          <b-td v-for="(item, index) in detailsData" :key="'th2-' + index">
                            {{ $n(item.production_target) }}
                          </b-td>
                        </template>
                      </b-tr>
                      <b-tr>
                        <b-th>{{ $t('teaGardenService.production_achievement') }}</b-th>
                        <template v-if="detailsData.length">
                          <b-td v-for="(item, index) in detailsData" :key="'th3-' + index">
                            {{ $n(item.production_achievement) }}
                          </b-td>
                        </template>
                      </b-tr>
                    </b-table-simple>
                 </template>
                 <template v-else>
                  <div class="text-center text-danger mt-5">
                    {{ $t('globalTrans.noDataFound') }}
                  </div>
                </template>
                  <!--- Month wise Production Report-->
                  <template v-if="monthWiseProduction.length">
                    <b-table-simple bordered small responsive>
                      <b-thead>
                        <b-tr class="text-center">
                          <b-th rowspan="2">{{ $t('globalTrans.month') }}</b-th>
                          <b-th colspan="2" width="25%" v-for="(item, index) in monthWiseProduction" :key="'th1-' + index">
                            {{ $t('teaGardenService.production') }} {{ $n(item.year, { useGrouping: false }) }}
                          </b-th>
                        </b-tr>
                        <b-tr class="text-center">
                          <template v-for="(item, index) in monthWiseProduction">
                            <b-th :key="'th2-' + index" width="14%">
                              {{ $t('teaGardenService.during_month') }}
                            </b-th>
                            <b-th :key="'th3-' + index" width="14%">
                              {{ $t('teaGardenService.upto_month') }}
                            </b-th>
                          </template>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <b-tr v-for="(item, index) in monthList" :key="'month-' + index" class="text-center">
                          <b-td>{{ currentLocale === 'en' ? item.text_en : item.text_bn }}</b-td>
                          <template v-for="(detailsItem, detailsIndex) in monthWiseProduction">
                            <b-td :key="'dm-' + detailsIndex">
                              {{ detailsItem.months[item.value].total_production ? $n(detailsItem.months[item.value].total_production) : '' }}
                            </b-td>
                            <b-td :key="'um-' + detailsIndex">
                              {{ detailsItem.months[item.value].total_production_upto_month ? $n(detailsItem.months[item.value].total_production_upto_month) : '' }}
                            </b-td>
                          </template>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </template>
                  <template v-else>
                    <div class="text-center text-danger mt-5">
                      {{ $t('globalTrans.noDataFound') }}
                    </div>
                  </template>
                </template>
              </b-overlay>
            </div>
          </template>
        </body-card>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { annualProductionApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
export default {
  name: 'AnnualProduction',
  components: {
    ListReportHead
  },
  props: ['item'],
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      search: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1
      },
      detailsData: [],
      monthWiseProduction: [],
      showData: false,
      buttonPdf: false
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    yearList () {
      return helpers.getYearList()
    },
    monthList () {
      return this.$store.state.commonObj.monthList.map(item => {
        return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
      })
    }
  },
  methods: {
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, annualProductionApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async searchData () {
      this.detailsData = []
      this.showData = true
      this.buttonPdf = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, annualProductionApi, this.search)
      if (result.success) {
        this.detailsData = result.data
        this.monthWiseProduction = result.monthWiseProduction
      } else {
        this.detailsData = []
        this.this.monthWiseProduction = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
