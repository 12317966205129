<template>
  <b-row>
    <b-col lg="12" sm="12">
      <template>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                  <template v-slot:label>
                    {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                    plain
                    id="fiscal_year_id"
                    v-model="search.fiscal_year_id"
                    :options="fiscalYearList"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                    <template v-slot:first>
                    <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                <div class="invalid-feedback d-block">
                    {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                <b-form-group label-for="month" slot-scope="{ valid, errors }">
                  <template v-slot:label>
                      {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                      plain
                      id="month"
                      v-model="search.month"
                      :options="monthList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <ValidationProvider name="country" vid="country_id" rules="">
                <b-form-group label-for="country_id" slot-scope="{ valid, errors }">
                  <template v-slot:label>
                      {{ $t('globalTrans.country') }}
                  </template>
                  <b-form-select
                      plain
                      id="country_id"
                      v-model="search.country_id"
                      :options="countryList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <b-button type="submit" size="sm" variant="primary" class="mt-20" style="font-size:13px">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
            <b-col class="text-right" v-if="buttonPdf">
              <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
              </b-button>
            </b-col>
          </b-row>
          </b-form>
      </ValidationObserver>
      </template>
      <body-card>
        <template v-slot:body>
          <div v-if="showData">
            <b-overlay :show="loader">
              <b-row>
                <b-col>
                  <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    {{ $t('teaGardenService.country_wise_import_report') }}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row class="justify-content-center mb-4">
                <b-col md="6" class="text-center">
                  <span>{{ $t('globalTrans.fiscal_year') }}: </span> {{ getFiscalYearName(search.fiscal_year_id) }}
                  <br>
                  <span> {{ $t('globalTrans.month') }} : </span> {{ getMonthList(search.month) }}
                  <br>
                  <span> {{ $t('globalTrans.country') }} : </span> {{ search.country_id ? getCountryName(search.country_id) : $t('globalTrans.all') }}
                  <br>
                  (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                  <br>
                </b-col>
              </b-row>
              <template>
                <b-table-simple bordered small responsive>
                  <b-thead>
                    <b-tr>
                      <b-th rowspan="2">{{ $t('teaGardenService.name_of_the_country') }}</b-th>
                      <b-th :colspan="masterMadeTeaTypeList.length + 1" class="text-center">{{ $t('teaGardenService.during_month') + ' "' }}{{ getMonthList(search.month) }} , {{ getFiscalYearName(search.fiscal_year_id) + '" ' }} </b-th>
                      <b-th :colspan="masterMadeTeaTypeList.length + 1" class="text-center">{{ $t('teaGardenService.import_up_to') + ' "' }}{{ getMonthList(search.month) }} , {{ getFiscalYearName(search.fiscal_year_id) + '" ' }}</b-th>
                    </b-tr>
                    <!-- All Tea Types name-->
                    <b-tr>
                      <b-th v-for="(item, index) in masterMadeTeaTypeList" :key="'first-' + index">
                        {{ currentLocale === 'en' ? item.text_en : item.text_bn }}
                      </b-th>
                      <b-th>{{ $t('globalTrans.total') }}</b-th>
                      <b-th v-for="(item, index) in masterMadeTeaTypeList" :key="'second-' + index">
                        {{ currentLocale === 'en' ? item.text_en : item.text_bn }}
                      </b-th>
                      <b-th>{{ $t('globalTrans.total') }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <tr v-for="(item, index) in countryListSearch" :key="index" class="text-center">
                      <b-td>{{ currentLocale === 'en' ? item.text_en : item.text_bn }}</b-td>
                        <template v-if="resultData.countries">
                          <template v-if="resultData.countries[item.value]">
                            <template v-if="resultData.countries[item.value].tea_types">
                              <b-td v-for="(teaTypeItem, teaTypeIndex) in resultData.countries[item.value].tea_types" :key="'first-body-' + teaTypeIndex">
                                {{ teaTypeItem.current_month_quantity ? $n(teaTypeItem.current_month_quantity, { useGrouping: false }) : '' }}
                              </b-td>
                            </template>
                              <b-td v-if="resultData.countries[item.value].total">
                                {{ resultData.countries[item.value].total.current_month_total_quantity ? $n(resultData.countries[item.value].total.current_month_total_quantity, { useGrouping: false }) : '' }}
                              </b-td>
                          </template>
                          <template v-else>
                            <b-td v-for="(item, index) in masterMadeTeaTypeList" :key="'first-def-' + index"></b-td>
                            <b-td></b-td>
                          </template>
                        </template>
                        <template v-if="resultData.countries">
                          <template v-if="resultData.countries[item.value]">
                            <template v-if="resultData.countries[item.value].tea_types">
                              <b-td v-for="(teaTypeItem, teaTypeIndex) in resultData.countries[item.value].tea_types" :key="'secound-body-' + teaTypeIndex">
                                {{ teaTypeItem.upto_month_quantity ? $n(teaTypeItem.upto_month_quantity, { useGrouping: false }) : '' }}
                              </b-td>
                            </template>
                              <b-td v-if="resultData.countries[item.value].total">
                                {{ resultData.countries[item.value].total.upto_month_total_quantity ? $n(resultData.countries[item.value].total.upto_month_total_quantity, { useGrouping: false }) : '' }}
                              </b-td>
                          </template>
                          <template v-else>
                            <b-td v-for="(item, index) in masterMadeTeaTypeList" :key="'second-def-' + index"></b-td>
                            <b-td></b-td>
                          </template>
                        </template>
                    </tr>
                  <b-tr class="text-center" v-if="resultData.total_info">
                    <b-td>{{ $t('globalTrans.total') }}</b-td>
                    <b-td v-for="(totalInfo, totalInfoIndex) in resultData.total_info.tea_type_info" :key="'total-cr' + totalInfoIndex">
                      {{ totalInfo.current_month_quantity > 0 ? $n(totalInfo.current_month_quantity, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>
                      {{ resultData.total_info.current_month_total_quantity > 0 ? $n(resultData.total_info.current_month_total_quantity, { useGrouping: false }) : '' }}
                    </b-td>

                    <b-td v-for="(totalInfo, totalInfoIndex) in resultData.total_info.tea_type_info" :key="'total-up' + totalInfoIndex">
                      {{ totalInfo.upto_month_quantity > 0 ? $n(totalInfo.upto_month_quantity, { useGrouping: false }) : '' }}
                    </b-td>
                    <b-td>{{ resultData.total_info.upto_month_total_quantity > 0 ? $n(resultData.total_info.upto_month_total_quantity, { useGrouping: false }) : '' }}</b-td>
                  </b-tr>
                  </b-tbody>
                </b-table-simple>
              </template>
            </b-overlay>
          </div>
        </template>
      </body-card>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { countryWiseImportReportApi } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
export default {
name: 'CountryWiseImport',
components: {
  ListReportHead
},
data () {
  return {
    teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
    search: {
      fiscal_year_id: 0,
      month: new Date().getMonth() + 1,
      country_id: 0
    },
    resultData: {
      countries: {},
      total_info: {
        tea_type_info: [],
        current_month_total_quantity: 0,
        upto_month_total_quantity: 0
      }
    },
    loader: false,
    showData: false,
    buttonPdf: false,
    teaTypesLength: 0,
    countryListSearch: this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
  }
},
created () {
  this.getCurrentFiscalYear()
},
computed: {
  currentLocale () {
    return this.$i18n.locale
  },
  loading () {
    return this.$store.state.commonObj.loading
  },
  yearList () {
    return helpers.getYearList()
  },
  monthList () {
    return this.$store.state.commonObj.monthList.map(item => {
      return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
    })
  },
  fiscalYearList () {
    return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
  },
  countryList () {
    return this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
  },
  masterMadeTeaTypeList () {
    return this.$store.state.TeaGardenService.commonObj.masterMadeTeaTypeList.filter(item => item.status === 1)
  }
},
watch: {
  // 'search.country_id': function (newVal) {
  //   if (newVal) {
  //     this.countryListSearch = this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && item.value === parseInt(newVal))
  //   } else {
  //     this.countryListSearch = this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
  //   }
  // }
},
methods: {
  getCurrentFiscalYear () {
    const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
    const currentDate = new Date()
    const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
    const yearPosition = month < 7 ? 5 : 0
    const yearStr = `${year}`
    fiscalYearList.forEach(element => {
      if (element.text_en.indexOf(yearStr) === yearPosition) {
        this.search.fiscal_year_id = element.value
      }
    })
  },
  async pdfExport () {
    this.loader = true
    const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
    const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
    if (service !== undefined) {
      if (service.office_type_id) {
        params.office_type_id = service.office_type_id
      }
      if (service.office_id) {
        params.office_id = service.office_id
      }
    }
    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, countryWiseImportReportApi, params)
    var blob = new Blob([result], {
      type: 'application/pdf'
    })
    var url = window.URL.createObjectURL(blob)
    window.open(url).print()
    this.loader = false
    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
  },
  async searchData () {
    this.resultData = {}
    this.showData = true
    this.loader = true
    this.buttonPdf = true
    if (this.search.country_id) {
      this.countryListSearch = this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && item.value === parseInt(this.search.country_id))
    } else {
      this.countryListSearch = this.$store.state.CommonService.commonObj.countryList.filter(item => item.status === 1 && (item.text_en.toLowerCase() !== 'bangladesh'))
    }
    const result = await RestApi.getData(teaGardenServiceBaseUrl, countryWiseImportReportApi, this.search)
    if (result.success) {
      this.resultData = result.data
    } else {
      this.resultData = {}
    }
    this.loader = false
  },
  getFiscalYearName (id) {
    const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
    if (typeof data !== 'undefined') {
      return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
    } else {
      return ''
    }
  },
  getMonthList (id) {
    const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
    if (typeof obj !== 'undefined') {
      return this.$i18n.locale === 'bn' ? obj.text_bn : obj.text_en
    } else {
      return ''
    }
  },
  getCountryName (id) {
    const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(id))
    return this.currentLocale === 'bn' ? obj?.text_bn : obj?.text_en
  }
}
}
</script>
