<template>
  <b-row>
    <b-col lg="12" sm="12">
      <template>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off">
            <b-row>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group label-for="fiscal_year_id" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('teaGardenService.season') }} <span class="text-danger">*</span>
                    </template>
                  <b-form-select
                      plain
                      id="fiscal_year_id"
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                  <b-form-group label-for="month" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                    </template>
                  <b-form-select
                      plain
                      id="month"
                      v-model="search.month"
                      :options="monthList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <ValidationProvider name="Aucation Location" vid="auction_location" rules="required|min_value:1">
                  <b-form-group label-for="auction_location" slot-scope="{ valid, errors }">
                    <template v-slot:label>
                      {{ $t('teaGardenService.auction_location') }} <span class="text-danger">*</span>
                    </template>
                  <b-form-select
                      plain
                      id="auction_location"
                      v-model="search.auction_location"
                      :options="locationList"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback d-block">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <b-button type="submit" size="sm" variant="primary" class="mt-20">
                  <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                </b-button>
              </b-col>
              <b-col xs="12" sm="12" md="2" lg="2" xl="2" class="text-right" v-if="showData">
                <b-button size="sm" variant="danger" class="mt-20" @click="pdfExport" style="font-size: 15px;">
                  <i class="ri-file-pdf-line"></i> {{ $t('globalTrans.export_pdf') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </template>
      <body-card>
        <template v-slot:body>
          <div v-if="showData">
            <b-overlay :show="loading">
              <b-row>
                <b-col>
                  <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="27">
                    <template v-slot:projectNameSlot>
                      {{ }}
                    </template>
                    {{ $t('teaGardenService.auction_sale') }} {{ $t('globalTrans.report') }}
                  </list-report-head>
                </b-col>
              </b-row>
              <b-row class="justify-content-center mb-4">
                <b-col md="6" class="text-center">
                  <span>{{ $t('teaGardenService.season') }}: </span> {{ getFiscalYearName(search.fiscal_year_id) }}, <span> {{ $t('globalTrans.month') }} : </span> {{ getMonthName(search.month) }}, <span>  {{ $t('teaGardenService.auction_location') }} : </span> {{ getLocationName(search.auction_location) }} <br>
                  (<span>{{ $t('globalTrans.quantity') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.kg') }})
                  <br>
                  (<span>{{ $t('teaGardenService.value') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }})
                  <br>
                  (<span>{{ $t('teaGardenService.avg_price') }}:</span> '<template>{{ '000' | numberConvert }}</template>' {{ $t('globalTrans.tk') }}/{{ $t('globalTrans.kg') }})
                </b-col>
              </b-row>
                <b-table-simple bordered small responsive>
                  <b-thead>
                    <b-tr>
                      <b-th rowspan="4">{{ $t('globalTrans.fiscal_year') }}</b-th>
                      <b-th colspan="6" class="text-center">{{ $t('teaGardenService.old_current_season_tea') }}</b-th>
                      <b-th colspan="6" class="text-center">{{ $t('teaGardenService.old_current_season_tea') }}</b-th>
                    </b-tr>
                    <b-tr>
                      <b-th colspan="6" class="text-center">{{ $t('teaGardenService.for_the_month') + ' "' }}{{ getMonthName(search.month) }} , {{ getFiscalYearName(search.fiscal_year_id) + '" ' }} </b-th>
                      <b-th colspan="6" class="text-center">{{ $t('teaGardenService.upto_month') + ' "' }}{{ getMonthName(search.month) }} , {{ getFiscalYearName(search.fiscal_year_id) + '" ' }}</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th colspan="2">{{ $t('teaGardenService.cst') }}</b-th>
                      <b-th colspan="2">{{ $t('teaGardenService.ost') }}</b-th>
                      <b-th colspan="2">{{ $t('globalTrans.total') }}</b-th>
                      <b-th colspan="2">{{ $t('teaGardenService.cst') }}</b-th>
                      <b-th colspan="2">{{ $t('teaGardenService.ost') }}</b-th>
                      <b-th colspan="2">{{ $t('globalTrans.total') }}</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                      <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                      <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                      <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                      <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                      <b-th>{{ $t('teaGardenService.qty') }}</b-th>
                      <b-th>{{ $t('teaGardenService.avg_price') }}</b-th>
                    </b-tr>
                    <b-tr class="text-center">
                      <b-th>{{ $n(1) }}</b-th>
                      <b-th>{{ $n(2) }}</b-th>
                      <b-th>{{ $n(3) }}</b-th>
                      <b-th>{{ $n(4) }}</b-th>
                      <b-th>{{ $n(5) }}</b-th>
                      <b-th>{{ $n(6) }}</b-th>
                      <b-th>{{ $n(7) }}</b-th>
                      <b-th>{{ $n(8) }}</b-th>
                      <b-th>{{ $n(9) }}</b-th>
                      <b-th>{{ $n(10) }}</b-th>
                      <b-th>{{ $n(11) }}</b-th>
                      <b-th>{{ $n(12) }}</b-th>
                      <b-th>{{ $n(13) }}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <template v-if="detailsData.length">
                      <tr v-for="(item, index) in detailsData" :key="index" class="text-center">
                        <td>{{ getFiscalYearName(item.fiscal_year_id) }}</td>
                        <td>{{ item.current_month_cst_sale_quantity ? $n(item.current_month_cst_sale_quantity, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.current_month_cst_average_value ? $n(item.current_month_cst_average_value, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.current_month_ost_sale_quantity ? $n(item.current_month_ost_sale_quantity, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.current_month_ost_average_value ? $n(item.current_month_ost_average_value, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.current_month_total_sale_quantity ? $n(item.current_month_total_sale_quantity, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.current_month_total_average_value ? $n(item.current_month_total_average_value, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.upto_month_cst_sale_quantity ? $n(item.upto_month_cst_sale_quantity, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.upto_month_cst_average_value ? $n(item.upto_month_cst_average_value, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.upto_month_ost_sale_quantity ? $n(item.upto_month_ost_sale_quantity, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.upto_month_ost_average_value ? $n(item.upto_month_ost_average_value, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.upto_month_total_sale_quantity ? $n(item.upto_month_total_sale_quantity, { useGrouping: false }) : '' }}</td>
                        <td>{{ item.upto_month_total_average_value ? $n(item.upto_month_total_average_value, { useGrouping: false }) : '' }}</td>
                      </tr>
                    </template>
                    <template v-else>
                      <tr>
                        <td colspan="13" class="text-center">{{ $t('globalTrans.no_data_found') }}</td>
                      </tr>
                    </template>
                  </b-tbody>
                </b-table-simple>
            </b-overlay>
          </div>
        </template>
      </body-card>
    </b-col>
  </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { auctionSaleApi } from '../../api/routes'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'

export default {
  name: 'AnnualProduction',
  components: {
    ListReportHead
  },
  props: ['item'],
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      search: {
        fiscal_year_id: 0,
        auction_location: 0,
        month: new Date().getMonth() + 1
      },
      detailsData: [],
      showData: false
    }
  },
  created () {
    this.getCurrentFiscalYear()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    monthList () {
      return this.$store.state.commonObj.monthList.map(item => {
        return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
      })
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    locationList () {
      return [
        {
          value: 1, text_en: 'Chattogram', text_bn: 'চট্টগ্রাম', text: this.currentLocale === 'en' ? 'Chattogram' : 'চট্টগ্রাম'
        },
        {
          value: 2, text_en: 'Sylhet', text_bn: 'সিলেট', text: this.currentLocale === 'en' ? 'Sylhet' : 'সিলেট'
        },
        {
          value: 3, text_en: 'Panchagarh', text_bn: 'পঞ্চগড়', text: this.currentLocale === 'en' ? 'Panchagarh' : 'পঞ্চগড়'
        }
      ]
    }
  },
  watch: {
    'search.fiscal_year_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.previousTwoYearFiscalYearId(newVal)
      }
    }
  },
  methods: {
    getCurrentFiscalYear () {
      const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          this.search.fiscal_year_id = element.value
        }
      })
    },
    async searchData () {
      this.detailsData = []
      this.showData = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, auctionSaleApi, this.search)
      if (result.success) {
        this.detailsData = result.data
      } else {
        this.detailsData = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    },
    getFiscalYearName (id) {
      const data = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    previousTwoYearFiscalYearId (id) {
      const obj = this.fiscalYearList.find(item => item.value === parseInt(id))
      const fiscalYearListSort = this.fiscalYearList.filter(item => item.sorting_order <= obj.sorting_order).sort((a, b) => b.sorting_order - a.sorting_order)
      const fiscalYearIds = []
      fiscalYearListSort.forEach(item => {
        if (fiscalYearIds.length < 3) {
            fiscalYearIds.push(item.value)
        }
      })
      this.search.fiscal_year_ids = fiscalYearIds
    },
    getMonthName (id) {
      const obj = this.$store.state.commonObj.monthList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getLocationName (id) {
      const obj = this.locationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async pdfExport () {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 27)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, auctionSaleApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
